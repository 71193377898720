<template>
  <div>
    <div class="popup open" v-if="memberInfoShow">
      <div class="popup_area">
        <button class="popup_close" @click="memberInfo(false)"></button>
        <div class="infoBox">
          <div class="leftBox">
            <div class="infoTit">基本資料</div>
            <ul class="form">
              <li>
                <h4>電號</h4>
                <p>16175800017</p>
              </li>
              <li>
                <h4>區處</h4>
                <p>台北北區營業處</p>
              </li>
              <li>
                <h4>行業別</h4>
                <p>公共行政</p>
              </li>
              <li>
                <h4>戶名</h4>
                <p>台北士林區公所</p>
              </li>
              <li>
                <h4>用電地址</h4>
                <p>台北市士林區中正路439號11樓及公共</p>
              </li>
              <li>
                <h4>服務專員</h4>
                <p>何春生</p>
              </li>
              <li>
                <h4>服務專線</h4>
                <p>02-28881678-550</p>
              </li>
            </ul>
            <div class="mobile"><div class="graphBorder"></div></div>
          </div>

          <div class="rightBox">
            <div class="rightBox1">
              <div class=" infoTitO infoTit">設備容量</div>
              <ul class="form">
                <li>
                  <h4>電力</h4>
                  <div class="formL">
                    <p>1,017.00 kW</p>
                  </div>
                </li>
                <li>
                  <h4>電熱</h4>
                  <div class="formL">
                    <p>0.00 kW</p>
                  </div>
                </li>
                <li>
                  <h4>電燈</h4>
                  <div class="formL">
                    <p>30.40 kW</p>
                  </div>
                </li>
                <li>
                  <h4>合計</h4>
                  <div class="formL">
                    <p>1,047.40 kW</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="graphBorder"></div>
            <div class="rightBox2">
              <div class="infoTitR infoTit">電價方案</div>
              <ul class="form">
                <li>
                  <h4>經常契約容量</h4>
                  <div class="formL">
                    <p>1,017.00 kW</p>
                  </div>
                </li>
                <li>
                  <h4>計費類別</h4>
                  <div class="formL">
                    <p>高壓二段式</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 頁面 -->
    <div class="wrapper innerBg">
      <!--header-->
      <div class="header">
        <div class="pagesize">
          <div class="headerNav">
            <router-link class="headerlogo" to="./index"><img src="./images/logo.png" alt="">臺北市士林區公所能源管理系統</router-link>
            <div class="state web">
              <div class="circle"></div>通訊正常
            </div>
            <ul class="headerNav_menu web">
            <li><router-link to="/index">首頁</router-link></li>
            <li><router-link to="./public" class="on">公共用電</router-link></li>
            <li><router-link to="./floor" >樓層用電</router-link></li>
            <li><router-link to="./ice" >冰水主機</router-link></li>
            <li><router-link to="./solar" >光電系統</router-link></li>
            <li><router-link to="./download" >下載專區</router-link></li>
            </ul>
            <div class="menuic mobile btn_menu">
              <img class="btn_pic01 btn_menuck" src="./images/icon_menu.png" alt="">
              <img class="btn_pic02 btn_menuck" src="./images/icon_close.png" alt="">
            </div>
          </div>
        </div>
        <!--小網menu-->
        <div class="open_nav">
          <ul>
            <li class="m_nav"><router-link to="./index">首頁</router-link></li>
            <li class="m_nav"><router-link to="./public">公共用電</router-link></li>
            <li class="m_nav"><router-link to="./floor">樓層用電</router-link></li>
            <li class="m_nav"><router-link to="./ice">冰水主機</router-link></li>
            <li class="m_nav"><router-link to="./solar">光電系統</router-link></li>
            <li class="m_nav"><router-link to="./download">下載專區</router-link></li>
          </ul>
          <div class="state">
            <div class="circle"></div>通訊正常
          </div>
        </div>
      </div>
      <!--內容開始-->
      <div class="contain">
        <div class="pagesize">
          <div class="main">
            <div class="lsDate">最後更新時間 {{upDateTime}}</div>
            <div class="leftfunc">
              <div class="funcBar mgb">
                <div class="box" @click="memberInfo(true)">
                  <p>用戶<br>基本資料<img src="./images/icon_user.png"></p>
                </div>
                <div class="box box2">
                  <p>尖峰需量<br>本月最高<img src="./images/icon_flash.png"></p>
                  <div class="tR">
                    <p class="num">{{topDemandMonth}}<span>kW</span></p>
                  </div>
                </div>
                <div class="box box3">
                  <div class="lBox">
                    <p>與去年同月<br>相比節電率</p>
                    <div class="tR">
                      <p class="num">{{kwSaveRateLastMonth}}<span>%</span></p>
                    </div>
                  </div>
                  <div class="boxBorder"></div>
                  <div class="lBox">
                    <p>今年<br>累積減碳<img src="./images/icon_co2.png"></p>
                    <div class="tR">
                      <p class="num">{{accCO2Reduction}}<span>kg</span></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="graph graphM">
                <p>用電歷史資訊</p>
                <div class="graphBar">
                  <div class="interval">
                    <input type="checkbox" id="c1" name="yesOrNo" v-model="selectCheck">
                    <label for="c1">選擇區間<span></span></label>
                  </div>
                  <div class="btn_date">
                    <a @click="dateTypeSelect('date')" :class="{ dateActive: DateType === 'date'}">日</a>
                    <a @click="dateTypeSelect('month')" :class="{ dateActive: DateType === 'month'}">月</a>
                    <a @click="dateTypeSelect('year')" :class="{ dateActive: DateType === 'year'}">年</a>
                  </div>
                  <div class="interCa">
                    <div class="inputCa inputW">
                      <!-- <input type="text" value="" placeholder="起始日期"> -->
                      <date-picker style="width:auto" placeholder="起始日期" v-model="beginDate" :type="DateType" valueType="format"></date-picker>
                      <!-- <button class="calendar"></button> -->
                    </div>
                    <p class="dib" v-if="selectCheck">至</p>
                    <div class="inputCa inputW" v-if="selectCheck">
                      <date-picker style="width:auto" placeholder="結束日期" v-model="endDate" :type="DateType" valueType="format"></date-picker>
                      <!-- <input type="text" value="" placeholder="結束日期">
                      <button class="calendar"></button> -->
                    </div>
                  </div>
                  <div class="btn_confirm dib">
                    <a @click="historyQuery(DateType)">確認</a>
                  </div>
                </div>
                <Highcharts :options="PublicEtodayChart" ref="highchart" />
                <!-- <img class="web" src="./images/graph4.png">
                <img class="mobile" src="./images/graph4_m.png"> -->
              </div>
            </div>
            <div class="rightfunc">
              <div class="graph graphM mgb">
                <p>去年同期用電</p>
                <div class="graphInfo">
                  <p class="subtit">去年同月最高用電度數</p>
                  <div class="flr green">
                    <p class="num">{{MaxkWhLastYear}}<span>度</span></p>
                  </div>
                </div>
                <div class="graphInfo">
                  <p class="subtit">去年同月用電度數</p>
                  <div class="flr orange">
                    <p class="num">{{MonthKwhLastYear}}<span>度</span></p>
                  </div>
                </div>
                <div class="graphInfo">
                  <p class="subtit">去年同月最高需量
                    <div class="flr red">
                      <p class="num">{{highDemandLastYear}}<span>kW</span></p>
                    </div>
                </div>
              </div>
              <div class="graph graphM">
                <p>本月累積用電數據</p>
                <div class="graphInfo">
                  <p class="subtit">本月最高用電度數</p>
                  <div class="flr green">
                    <p class="num">{{mostHighKwh}}<span>度</span></p>
                  </div>
                </div>
                <div class="graphInfo">
                  <p class="subtit">本月累積用電度數</p>
                  <div class="flr orange">
                    <p class="num">{{cumMonKwh}}<span>度</span></p>
                  </div>
                </div>
                <div class="graphInfo">
                  <p class="subtit">通訊可靠度
                    <div class="flr red">
                      <p class="num">{{connectReliability}}<span>%</span></p>
                    </div>
                    <div class="bar">
                      <div class="bar barNum" :style="connectReliabilityBar"></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--footer-->
      <footer>
        <div class="pagesize">
          <div class="ftLogo"><img src="./images/logo2.png" alt="">
          </div>
          <div class="copyright">
            Copyright © All Rights Reserved.
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<style scoped>
  @import './css/global.css';
  a {
    cursor: pointer;
  }
  .dateActive {
    background-color: #128771;
    color: white
  }
  .inputW {
    width: 170px;
  }
</style>

<script>
import importJQ from './js/common'
import $ from 'jquery'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mapActions, mapState, mapMutations } from 'vuex'
import { dbDataHandle } from '../store/function/index'
import { DateTime } from 'luxon'
import VueCookies from 'vue-cookies'
import axios from 'axios'
export default {
  components: { DatePicker },
  data () {
    return {
      MaxkWhLastYear: 0,
      MonthKwhLastYear: 0,
      connectReliability: 0,
      highDemandLastYear: 0,
      cumMonKwh: 0,
      mostHighKwh: 0,
      connectReliabilityBar: {
        width: 0
      },
      accCO2Reduction: 0,
      topDemandMonth: 0,
      kwSaveRateLastMonth: 0,
      selectCheck: false,
      DateType: 'date',
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      endDate: DateTime.local().toFormat('yyyy-MM-dd'),
      memberInfoShow: false,
      PublicEtodayChart: {
        chart: {
          type: 'line',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        plotOptions: {
          line: {
            dataLabels: {
              // enabled: true
            }
            // enableMouseTracking: false
          }
        },
        series: [{
          name: '用電量(度)',
          data: [],
          color: '#4cc0e2'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'GETUPDATE_TIME'
    ]),
    ...mapActions([
      'getdayData',
      'getHourData',
      'getDayInterval',
      'getMonInterval',
      'getYearInterval',
      'getMonData'
    ]),
    handleNum (val, num) {
      return dbDataHandle(val, num)
    },
    memberInfo (boolean) {
      this.memberInfoShow = boolean
    },
    dateTypeSelect (str) {
      this.DateType = str
      if (str === 'date') {
        this.beginDate = DateTime.local().toFormat('yyyy-MM-dd')
        this.endDate = DateTime.local().toFormat('yyyy-MM-dd')
      }
      if (str === 'month') {
        this.beginDate = DateTime.local().toFormat('yyyy-MM')
        this.endDate = DateTime.local().toFormat('yyyy-MM')
      }
      if (str === 'year') {
        this.beginDate = DateTime.local().toFormat('yyyy')
        this.endDate = DateTime.local().toFormat('yyyy')
      }
    },
    async historyQuery (type) {
      if (!this.selectCheck) {
        if (type === 'date') {
          await this.getHourData(this.beginDate)
          this.getPublicEtodayhour()
        } else if (type === 'month') {
          await this.getdayData(this.beginDate)
          this.clearChart()
          this.DateChart(this.EdayData)
        } else if (type === 'year') {
          await this.getMonData(this.beginDate)
          this.clearChart()
          this.DateChart(this.EmonData)
        }
      } else {
        const date = {
          beginDate: this.beginDate,
          endDate: this.endDate
        }
        if (type === 'date') {
          await this.getDayInterval(date)
          this.clearChart()
          this.DateChart(this.InterData)
        }
        if (type === 'month') {
          await this.getMonInterval(date)
          this.clearChart()
          this.DateChart(this.MonInterData)
        }
        if (type === 'year') {
          await this.getYearInterval(date)
          this.clearChart()
          this.DateChart(this.YearInterData)
        }
      }
    },
    getPublicEtodayhour () {
      this.clearChart()
      for (let i = 0; i < this.EhourData.data.length; i++) {
        this.PublicEtodayChart.xAxis.categories.push((this.EhourData.data[i].hhmm).slice(0, 2))
        this.PublicEtodayChart.series[0].data.push(this.handleNum(this.EhourData.data[i].detail.public.meter))
      }
    },
    clearChart () {
      this.PublicEtodayChart.xAxis.categories = []
      this.PublicEtodayChart.series[0].data = []
    },
    DateChart (array) {
      for (let i = 0; i < array.data.length; i++) {
        this.PublicEtodayChart.xAxis.categories.push(array.data[i].date)
        this.PublicEtodayChart.series[0].data.push(this.handleNum(array.data[i].detail.public.meter))
      }
    },
    async cumulativeMonKwh () {
      const date = {
        beginDate: DateTime.local().toFormat('yyyy-MM'),
        endDate: DateTime.local().toFormat('yyyy-MM')
      }
      await this.getMonInterval(date)
      this.cumMonKwh = this.handleNum(this.MonInterData.data[0].detail.public.meter)
    },
    async theMostHighKwh () {
      await this.getdayData(DateTime.local().toFormat('yyyy-MM'))
      const culArray = []
      for (let i = 0; i < this.EdayData.data.length; i++) {
        culArray.push(this.EdayData.data[i].detail.public.meter)
      }
      const max = this.handleNum(Math.max(...culArray))
      this.mostHighKwh = max
    },
    async getMaxkWhLastYear () {
      const token = VueCookies.get('Authorization')
      const maxkWhLastYear = await axios.post('/api/ems/shilin/maxkWhLastYear',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (maxkWhLastYear.data.success) {
        this.MaxkWhLastYear = this.handleNum(maxkWhLastYear.data.data)
      }
    },
    async getMonthKwhLastYear () {
      const token = VueCookies.get('Authorization')
      const monthKwhLastYear = await axios.post('/api/ems/shilin/monthKwhLastYear',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (monthKwhLastYear.data.success) {
        console.log(monthKwhLastYear.data.data)
        this.MonthKwhLastYear = this.handleNum(monthKwhLastYear.data.data.detail.public.meter)
      }
    },
    async getConnectReliability () {
      const token = VueCookies.get('Authorization')
      const connectReliability = await axios.post('/api/ems/shilin/connectReliability',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (connectReliability.data.success) {
        this.connectReliability = this.handleNum(connectReliability.data.data, 0)
        if (this.handleNum(connectReliability.data.data, 0) <= 100) {
          this.connectReliabilityBar.width = `${this.handleNum(connectReliability.data.data, 0)}%`
        } else {
          this.connectReliabilityBar.width = '100%'
        }
      }
    },
    async getAccCO2Reduction () {
      const token = VueCookies.get('Authorization')
      const accCO2Reduction = await axios.post('/api/ems/shilin/accCO2Reduction',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (accCO2Reduction.data.success) {
        this.accCO2Reduction = this.handleNum(accCO2Reduction.data.data, 0)
      }
    },
    async getTopDemandMonth () {
      const token = VueCookies.get('Authorization')
      const topDemandMonth = await axios.post('/api/ems/shilin/topDemandMonth',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (topDemandMonth.data.success) {
        this.topDemandMonth = this.handleNum(topDemandMonth.data.data)
      }
    },
    async gethighDemandLastYear () {
      const token = VueCookies.get('Authorization')
      const highDemandLastYear = await axios.post('/api/ems/shilin/highDemandLastYear',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (highDemandLastYear.data.success) {
        this.highDemandLastYear = this.handleNum(highDemandLastYear.data.data)
      }
    },
    async getkwSaveRateLastMonth () {
      const token = VueCookies.get('Authorization')
      const kwSaveRateLastMonth = await axios.post('/api/ems/shilin/kwSaveRateLastMonth',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (kwSaveRateLastMonth.data.success) {
        this.kwSaveRateLastMonth = this.handleNum(kwSaveRateLastMonth.data.data)
      }
    }
  },
  async mounted () {
    await importJQ()
    this.getMaxkWhLastYear()
    this.getMonthKwhLastYear()
    this.getConnectReliability()
    this.getAccCO2Reduction()
    this.getTopDemandMonth()
    this.gethighDemandLastYear()
    this.getkwSaveRateLastMonth()
    this.cumulativeMonKwh()
    this.theMostHighKwh()
    await this.getHourData(this.beginDate)
    this.getPublicEtodayhour()
    this.GETUPDATE_TIME()
    $(function () {
      $(document).ready(function () {
        var $win = $(window)
        $win.bind('resize', function () {
          try {
            $('.contain').css('min-height', $win.height() - $('footer').outerHeight() - $('.contain').offset().top)
          } catch (err) {}
        }).resize()
      })
    })
  },
  computed: {
    ...mapState([
      'upDateTime',
      'EdayData',
      'EhourData',
      'InterData',
      'MonInterData',
      'YearInterData',
      'EmonData'
    ])
  }
}
</script>
